/* eslint-disable camelcase */

import _ from 'lodash'
import axios from 'axios'

const state = () => ({
  new: null,
  dataDetailSurvey: null,
  dataDetailPenawaran: null,
  dataDetailPenawaranRejected: null,
  dataPilihTukang: null,
  dataFotoSurvey: null,
  dataJadwalPekerjaan: null,
  getDurationData: null,
  dataLengkapiMaterial: null,
  dataAlertAssignTukang: false,
  dataHeaderProject: null,
  dataHistoryAttachment: null,
  dataUpdateItemPekerjaanBS: {
    surveyId: null,
    isUpdate: false,
    initCheckin: null,
    initPilihTukang: null,
    initFotoSurvey: null,
    initJadwalPekerjaan: null,
    initLengkapiMaterial: null
  },
  seeCombineGlobal: {
    on: false,
    color: null,
    rgba: null
  },
  previewDownloadOffering: false,
  dataListFilter: {
    vendor: null,
    sort: null
  },
  scrollElement: {
    selectedTab: 1,
    scroll: false,
    element: '',
    selectedDate: null
  },
  updatedItemPekerjaan: false
})

const getters = {
  getSesi: (state, getters, rootState) => {
    return state.dataSesi
  },
  getNewProject: (state, getters, rootState) => {
    return state.new
  },
  getRescheduleProject: (state, getters, rootState) => {
    return state.new
  },
  getDataDetailSurvey: (state, getters, rootState) => {
    return state.dataDetailSurvey
  },
  getDataDetailPenawaran: (state, getters, rootState) => {
    return state.dataDetailPenawaran
  },
  getDataDetailPenawaranRejected: (state, getters, rootState) => {
    return state.dataDetailPenawaranRejected
  },
  getDataPilihTukang: (state, getters, rootState) => {
    return state.dataPilihTukang
  },
  getDataFotoSurvey: (state, getters, rootState) => {
    return state.dataFotoSurvey
  },
  getDataJadwalPekerjaan: (state, getters, rootState) => {
    return state.dataJadwalPekerjaan
  },
  getDurationData: (state, getters, rootState) => {
    return state.getDurationData
  },
  getDataLengkapiMaterial: (state, getters, rootState) => {
    return state.dataLengkapiMaterial
  },
  getDataUpdateItemPekerjaanBS: (state, getters, rootState) => {
    return state.dataUpdateItemPekerjaanBS
  },
  getSeeCombineGlobal: (state, getters, rootState) => {
    return state.seeCombineGlobal
  },
  getPreviewDownloadOffering: (state, getters, rootState) => {
    return state.previewDownloadOffering
  },
  getDataListFilter: (state, getters, rootState) => {
    return state.dataListFilter
  },
  getAlertAssignTukang: (state, getters, rootState) => {
    return state.dataAlertAssignTukang
  },
  getScrollElement: (state, getters, rootState) => {
    return state.scrollElement
  },
  getHeaderProject: (state, getters, rootState) => {
    return state.dataHeaderProject
  },
  getHistoryAttachment: (state, getters, rootState) => {
    return state.dataHistoryAttachment
  },
  getUpdatedItemPekerjaan: (state, getters, rootState) => {
    return state.updatedItemPekerjaan
  }
}

const actions = {
  setNewProject ({ commit, state }, project) {
    commit('newProject', project)
  },
  setRescheduleProject ({ commit, state }, project) {
    commit('rescheduleProject', project)
  },
  insertTransactionProducts ({ commit, state }, products) {
    commit('setTransactionProduct', products)
  },
  gettingImageProducts ({ commit, state }) {
    _.forEach(state.new.transactionProduct.listProduct, (v, i) => {
      setTimeout(() => {
        axios.get(v.i_uri)
          .then((cb) => {
            const product = { image_uri: cb.data.data.url, index: i }
            commit('setImageUri', product)
          })
      }, 500)
    })
  },
  getTotalDataTabs ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_TOTAL_DATA_TABS}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveyProcess ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?status=survey_in_process&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveyBerlangsung ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?status=survey_in_progress&substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveyBerlangsungDashboard ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?status=survey_in_progress&substatus=today&sort=desc`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveyBerlangsungDashboardSM ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?substatus=progress&sort=desc`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveySelesai ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?status=survey_complete&substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getSurveyBatal ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?status=survey_cancel&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListMenungguKonfirmasi ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?status=waiting&substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPenawaranPending ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?status=pending&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPenawaranApproved ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?status=approved&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPenawaranRejected ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?status=rejected&substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataReschedule ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?substatus=reschedule&page=${payload.page}&limit=${payload.limit}${concatUrl}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDetailSurvey ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/survey/${payload.survey_id}/detail`)
        .then((response) => {
          commit('setDataDetailSurvey', response.data.data)
          resolve(response)
        }).catch((error) => {
          commit('setDataDetailSurvey', null)
          reject(error)
        })
    })
  },
  getDetailPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/${payload.offering_id}/detail`)
        .then((response) => {
          commit('setDataDetailPenawaran', response.data.data)
          resolve(response)
        }).catch((error) => {
          commit('setDataDetailPenawaran', null)
          reject(error)
        })
    })
  },
  getDetailPenawaranRejected ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/${payload.offering_id}/rejected`)
        .then((response) => {
          commit('setDataDetailPenawaranRejected', response.data.data)
          resolve(response)
        }).catch((error) => {
          commit('setDataDetailPenawaranRejected', null)
          reject(error)
        })
    })
  },
  getTotalRejected ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/${payload.offering_id}/total/rejected`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getCheckinPemeriksaan ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS

    let urlAPI = `${process.env.VUE_APP_PROJECT}/survey/${payload.survey_id}/checkin`
    if (dataFromBS.isUpdate) {
      // urlAPI = `${process.env.VUE_APP_DATA_PENAWARAN_V2}/checkin-reject/${dataFromBS.surveyId}/checkin`
      urlAPI = `${process.env.VUE_APP_PROJECT}/survey/${dataFromBS.surveyId}/checkin`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${urlAPI}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataAreaMaster ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_AREAS}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDetailComponents ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_AREAS}/components${payload.vendor_id ? `?vendor_id=${payload.vendor_id}` : ''}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataCheckin ({ commit, state, rootState }, payload) {
    const isSiteManager = rootState.auth.user.roles[0].name === 'Site Manager'
    const isBusinessSupport = rootState.auth.user.roles[0].name === 'Account Manager'
    const dataFromBS = state.dataUpdateItemPekerjaanBS

    let urlAPI = `${process.env.VUE_APP_DATA_SURVEY}/checkin`

    if (isBusinessSupport || isSiteManager) {
      urlAPI = `${process.env.VUE_APP_DATA_PENAWARAN_V2}/working-item-update`
    }

    if (dataFromBS.isUpdate) {
      urlAPI = `${process.env.VUE_APP_DATA_PENAWARAN_V2}/checkin-reject`
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${urlAPI}`, {
        survey_id: dataFromBS.isUpdate ? dataFromBS.surveyId : payload.survey_id,
        checkin: payload.checkin
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDataPilihTukang ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const dataFromBS = state.dataUpdateItemPekerjaanBS

      axios.get(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}/step`}/one?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataFotoSurvey ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    const path = dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject/` : 'v2/step/'

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${path}photo?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataJadwalPekerjaan ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}/step`}/two?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataLengkapiMaterial ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}/step`}/three?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataPilihTukang ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    const req = {
      survey_id: payload.survey_id || dataFromBS.surveyId,
      duration: payload.duration,
      area_components: payload.area_components
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}/step`}/one`, req)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postDataFotoSurvey ({ commit, state }, payload) {
    const data = new FormData()
    const obj = payload.photo
    const dataFromBS = state.dataUpdateItemPekerjaanBS

    data.append('survey_id', payload.survey_id || dataFromBS.surveyId)
    for (var key in obj) {
      for (const item of obj[key]) {
        if (item && item.picture) {
          data.append(key, item.picture)
        }
      }
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_PROJECT}/offering/step`}/photo`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  deleteDataFotoSurvey ({ commit, state }, payload) {
    const body = {
      survey_id: parseInt(payload.survey_id),
      key: payload.key,
      index: payload.index
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/step/photo/delete`, body).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  postDataJadwalPekerjaan ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_PROJECT}/offering/step`}/two`, {
        survey_id: payload.survey_id || dataFromBS.surveyId,
        area_components: payload.area_components
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  postDataLengkapiMaterial ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}/step`}/three`, {
        survey_id: payload.survey_id || dataFromBS.surveyId,
        area_components: payload.area_components
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListUnit ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_UNITS}?type=${payload.type || 'volume'}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataPreview ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_PROJECT}/offering`}/overview?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataDetailClient ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let temp = ''
      if (payload.preview) {
        temp = '?preview=true'
      }
      if (payload.tenant) {
        if (temp) {
          temp += `&tenant=${1}`
        } else {
          temp = `?tenant=${1}`
        }
      }

      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/${payload.offering_id}/detail/client${temp}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  completeSurvey ({ commit, state }, payload) {
    const body = {
      note: payload.note
    }
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}/${payload.survey_id}/complete`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // completeSurvey ({ commit, state }, payload) {
  //   const data = new FormData()
  //   payload.photo_survey.forEach(file => {
  //     data.append('photo_survey', file)
  //   })
  //   for (let i = 0; i < payload.photo_survey.length; i++) {
  //     data.append('photo_survey', payload.photo_survey[i].file)
  //   }

  //   if (payload.note && payload.note !== '') {
  //     data.append('note', payload.note)
  //   }

  //   const config = {
  //     Headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   }

  //   return new Promise((resolve, reject) => {
  //     axios.put(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}/${payload.survey_id}/complete`, data, config
  //     ).then((response) => {
  //       resolve(response)
  //     }).catch((error) => {
  //       reject(error)
  //     })
  //   })
  // },
  createPenawaran ({ commit, state }, payload) {
    const dataFromBS = state.dataUpdateItemPekerjaanBS

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}`}/overview/submit`, {
        survey_id: payload.survey_id || dataFromBS.surveyId
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // cancelOffering ({ commit, state }, payload) {
  //   const dataFromBS = state.dataUpdateItemPekerjaanBS
  //   return new Promise((resolve, reject) => {
  //     axios.get(`${payload.url}${dataFromBS.isUpdate ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject` : `${process.env.VUE_APP_DATA_PENAWARAN}`}/overview/cancel?survey_id=${payload.survey_id || dataFromBS.surveyId}`)
  //       .then((response) => {
  //         resolve(response)
  //       }).catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  cancelOffering ({ commit, state, rootState }, payload) {
    const dataFromBS = rootState.project.dataUpdateItemPekerjaanBS
    const body = {
      latest_step: dataFromBS.isUpdate ? undefined : payload.latest_step
    }
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${dataFromBS.isUpdate
        ? `${process.env.VUE_APP_DATA_PENAWARAN_V2}/step-reject/overview/cancel?survey_id=${payload.survey_id || dataFromBS.surveyId}`
        : `${process.env.VUE_APP_STEP_V2}/skill/update-latest-step?survey_id=${payload.survey_id || dataFromBS.surveyId}`}`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListPenawaranDashboard ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?substatus=waiting&sort=desc`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListAllSurvey ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}?substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListMenungguApproval ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListProyekBerlangsung ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/list?status=progress&sort=${payload.sort || 'desc'}&substatus=${payload.substatus}${concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListProyekSelesai ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/list?status=complete&sort=${payload.sort || 'desc'}&substatus=${payload.substatus}${concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListTagihanProyek ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search) {
      concatUrl = `&search=${payload.search}`
    }

    if (payload.substatus) {
      concatUrl = concatUrl + `&substatus=${payload.substatus}`
    }

    if (payload.vendor) {
      concatUrl = concatUrl + `&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/list?status=${payload.status}${concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListProyekLunas ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/list?status=paid${concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getCardTagihan ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/card`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataTotalInvoice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/${payload.transaction_id}/projects`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDataInvoice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/invoice`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  checkPublishInvoice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/invoice-checking`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  putPublishInvoice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/invoice-publish`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getRecipientInvoice ({ commit, state }, payload) {
    const { url, limit = 5, page = 1 } = payload

    return new Promise((resolve, reject) => {
      axios.get(`${url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/invoice-recipient?limit=${limit}&page=${page}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postRecipientInvoice ({ commit, state }, payload) {
    const body = {
      project_id: payload.project_id,
      name: payload.name
    }
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/invoice-recipient`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateAddressInvoice ({ commit, state }, payload) {
    const body = {
      project_id: payload.project_id,
      address: payload.address
    }
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/invoice-address`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  putConfirmPayment ({ commit, state }, payload) {
    const body = {
      paid_date: payload.paid_date
    }

    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/${payload.project_id}/confirm_payment`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getProjectHeader ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.proyek_id}/detail/header`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDetailHarga ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.offering_id}/detail/price`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getDetailProyek ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.proyek_id}/detail/body?category=${payload.category}${payload.subcategory ? `&subcategory=${payload.subcategory}${payload.tab ? '&tab=' + payload.tab : ''}${payload.selected_date ? '&selected_date=' + payload.selected_date : ''}` : ''}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          commit('setDataDetailPenawaran', null)
          reject(error)
        })
    })
  },
  getNoteProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.proyek_id}/detail/body?category=note`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getNoteProjectHistory ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/project-notes/${payload.survey_id}/history?page=${payload.page || 1}&limit=${payload.limit || 5}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updatePersonalNote ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/notes`, {
        survey_id: payload.survey_id,
        notes: payload.notes
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateProjectNote ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/project-notes`, {
        survey_id: payload.survey_id,
        note: payload.notes
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getDetailStatusMaterial ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.proyek_id}/detail/status-material`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListMenungguKonfirmasiPurchasing ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(
        `${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}?status=${payload.status}&substatus=${payload.substatus}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`
      )
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getListProjectApi ({ commit, state }, payload) {
    let concatUrl = ''

    if (payload.search_by && payload.search) {
      concatUrl = `&search_by=${payload.search_by}&search=${payload.search}`
    }

    if (payload.vendor) {
      concatUrl = `${concatUrl}&vendor=${payload.vendor}`
    }

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_LIST_PROJECT}?status=${payload.status}&sort=${payload.sort + concatUrl}&page=${payload.page}&limit=${payload.limit}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  projectOfferApi ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.offer_id}/detail`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getLengkapiPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/account-manager?survey_id=${payload.survey_id}${payload.is_filled ? '&is_filled=1' : '&is_filled=0'}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  lengkapiPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const body = {
        survey_id: payload.survey_id,
        data: payload.data,
        client_type: payload.client_type
      }

      if (payload.is_use_unit_price) {
        body.is_use_unit_price = true
      }

      axios.post(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/account-manager`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getPreviewLengkapiPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/account-manager/preview?survey_id=${payload.survey_id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/account-manager/submit`, {
        survey_id: payload.survey_id
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateDiscountPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.offering_id}/update/grand`, {
        discount: payload.discount
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getUpdateHargaPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.offering_id}/update/unit`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getUpdateHargaPenawaranV2 ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN_V2}/unit-reject/${payload.offering_id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateHargaSatuanPenawaran ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.offering_id}/update/unit`, {
        area_components: payload.data
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateHargaSatuanPenawaranV2 ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN_V2}/unit-reject/${payload.offering_id}/next`, {
        area_components: payload.data
        // client_type: payload.client_type
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createProject ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/order`, {
        offering_id: payload.offering_id
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postCompleteOffer ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/purchasing`, {
        offering_id: payload.offer_id,
        duration_material_preparation: payload.duration
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteDetailComponent ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/complete/account-manager/${payload.item_component_id}/delete`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postGrouping ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/step/two/group/${payload.survey_id}`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  removeGrouping ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/step/two/group/${payload.survey_id}/remove`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateGrouping ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/step/two/group/${payload.survey_id}/update`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postMaterial ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/material/process`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateStatusMaterial ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/material/confirm`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  deleteMaterialOrder ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/material?material_order_id=${payload.material_id}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updatePurchase ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.project_id}/material/update-purchase`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postReschedule ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_DATA_SURVEY}/${payload.path}`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postProjectUrgentId ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/urgent`, payload.data)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateInvoice ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/${payload.project_id}/invoice`, {
        invoice: payload.invoice
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateTanggalPenagihan ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/bill/${payload.project_id}/billing-date`, {
        date: payload.date
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateDestination ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/${payload.material_order_id}/material/destination`, {
        destination: payload.destination
      })
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createSurveyComplaint ({ commit, state }, payload) {
    const data = new FormData()

    if (payload.survey_id && payload.survey_id !== '') {
      data.append('survey_id', payload.survey_id)
    }

    for (let i = 0; i < payload.problem_images.length; i++) {
      data.append('problem_images', payload.problem_images[i].file)
    }

    if (payload.description && payload.description !== '') {
      data.append('description', payload.description)
    }

    if (payload.pic_id && payload.pic_id !== '') {
      data.append('pic_id', payload.pic_id)
    }

    if (payload.date_sesi && payload.date_sesi !== '') {
      data.append('date_sesi', payload.date_sesi)
    }

    if (payload.time_sesi_id && payload.time_sesi_id !== '') {
      data.append('time_sesi_id', payload.time_sesi_id)
    }

    if (payload.client_external_id && payload.client_external_id !== '') {
      data.append('client_external_id', payload.client_external_id)
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_SURVEY_COMPLAINT}`, data, config
      ).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getListPICComplaint ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_SURVEY_COMPLAINT}/pic?branch_id=${payload.branchId}`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  createPICComplaint ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_SURVEY_COMPLAINT}/pic`, payload.body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  checkScheduleComplain ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      const body = {
        date_sesi: payload.date,
        survey_id: payload.surveyId
      }
      axios.post(`${payload.url}${process.env.VUE_APP_SURVEY_COMPLAINT}/check-schedule`, body)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getOfferingClientType ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_DATA_PENAWARAN}/${payload.survey_id}/client-types`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  updateAttachment ({ commit, state }, payload) {
    // eslint-disable-next-line camelcase
    const { type, note, attachment, survey_id } = payload

    const data = new FormData()
    data.append('type', type)
    data.append('attachment', attachment)
    if (note) {
      data.append('note', note)
    }

    const config = {
      Headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line camelcase
      axios.put(`${payload.url}${process.env.VUE_APP_SPK_SIK}/${survey_id}/update`, data, config)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getHistoryAttachment ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_SPK_SIK}/${payload.survey_id}/histories`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  postCancelSurvey ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${payload.url}${process.env.VUE_APP_PROJECT}/survey/cancel`, {
        survey_id: payload.payload.survey_id,
        description: payload.payload.description
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  },
  postEditPhotos ({ commit }, { url, payload }) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      axios.put(`${url}${process.env.VUE_APP_PROJECT}/survey/image`, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
        })
    })
  },
  getSesiSurvey ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${payload.url}${process.env.VUE_APP_DATA_SURVEY}/${payload.survey_id}/sesi?date=${payload.date}`
      if (payload.store_id) {
        url += `&store_id=${payload.store_id}`
      }
      if (payload.survey_id) {
        url += `&survey_id=${payload.survey_id}`
      }
      if (payload.start_time) {
        url += `&start_time=${payload.start_time}`
      }
      if (payload.end_time) {
        url += `&end_time=${payload.end_time}`
      }
      if (payload.vendor_id) {
        url += `&vendor_id=${payload.vendor_id}`
      }

      axios.get(url)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  // expiredOffering ({ commit, state }, payload) {
  //   return new Promise((resolve, reject) => {
  //     let url = `${payload.url}${process.env.VUE_APP_DATA_SURVEY}/${payload.surveyId}/sesi?date=${payload.date}`
  //     if (payload.survey_id) {
  //       url += `&survey_id=${payload.survey_id}`
  //     }
  //     if (payload.start_time) {
  //       url += `&start_time=${payload.start_time}`
  //     }
  //     if (payload.end_time) {
  //       url += `&end_time=${payload.end_time}`
  //     }

  //     axios.get(url)
  //       .then((response) => {
  //         resolve(response)
  //       }).catch((error) => {
  //         reject(error)
  //       })
  //   })
  // },
  postEditNoteSurvey ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/survey/note`, {
        survey_id: payload.payload.survey_id,
        note: payload.payload.note
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
        })
    })
  },
  expiredOffering ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${payload.url}${process.env.VUE_APP_PROJECT}/offering/${payload.offering_id}/expired`)
        .then((response) => {
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

const mutations = {
  newProject (state) {
    state.new = {
      idtransaction_item: '',
      address: '',
      amount: 0,
      fee_building: 0,
      biaya_parkir: 0,
      net_amount: 0,
      customer_name: '',
      email: '',
      project_name: '',
      description: '',
      product_id: [],
      service_id: [],
      duration: '',
      start_date: '',
      end_date: '',
      jenis_bangunan: '',
      building_id: '',
      latitude: -6.175381728927934,
      longitude: 106.8269489488614,
      user_id: 0,
      vendor_id: 1,
      detail_sesi: [],
      item: [],
      transaction_id: '',
      branch: 1,
      customer_id: null,
      schedules: [],
      is_pph: 0,
      client_id: null
    }
  },

  rescheduleProject (state) {
    state.new = {
      project_id: '',
      detail_sesi_resch: []
    }
  },

  setCustomerId (state, val) {
    state.new.customer_id = val
  },
  setInfo (state, info) {
    state.new.idtransaction_item = info.idtransaction_item
    state.new.user_id = info.user_id
    state.new.transaction_id = info.transaction_id
  },
  setInfoDate (state, info) {
    state.new.duration = info.duration
    state.new.start_date = info.start_date
    state.new.end_date = info.end_date
  },
  setItem (state, items) {
    state.new.item = items
  },
  setProductId (state, products) {
    state.new.product_id = products
  },
  setServiceId (state, services) {
    state.new.service_id = services
  },
  setAmount (state, total) {
    state.new.amount = total.amount
    state.new.net_amount = total.net_amount
    state.new.fee_building = total.fee_building
  },
  setCustomer (state, customer) {
    state.new.customer_name = customer.name
    state.new.cell_phone = customer.cell_phone
  },
  setTransactionProduct (state, products) {
    state.new.transactionProduct.listProduct = products
  },
  setTransactionProductSelected (state, transaction) {
    state.new.transaction_id = transaction.id
    state.new.transactionProduct.transaction = transaction.products
  },
  setAddress (state, val) {
    state.new.address = val.address
    state.new.longitude = val.lng
    state.new.latitude = val.lat
    state.new.jenis_bangunan = val.jenis_bangunan
    state.new.building_id = val.building_id
  },
  setImageUri (state, product) {
    state.new.transactionProduct.listProduct[product.index].image_uri = product.image_uri
  },
  setDetailSesi (state, val) {
    state.new.detail_sesi.push(val)
  },
  clearDetailSesi (state) {
    state.new.detail_sesi = []
  },
  clearAddress (state) {
    state.new.address = ''
    state.new.longitude = ''
    state.new.latitude = ''
    state.new.jenis_bangunan = ''
    state.new.building_id = ''
  },
  setItemTukang (state, items) {
    state.new.item = items
  },
  clearItemTukang (state) {
    state.new.item = []
  },
  setDescription (state, description) {
    state.new.description = description
  },
  setBiayaParkir (state, biayaParkir) {
    state.new.biaya_parkir = biayaParkir
  },
  setIsPph (state, isPph) {
    state.new.is_pph = isPph
  },
  setIsClient (state, isClientId) {
    state.new.client_id = isClientId
  },
  setProjectName (state, isProjectName) {
    state.new.project_name = isProjectName
  },
  setSchedule (state, data) {
    state.new.skills = data.skills
    state.new.products = data.products
    state.new.schedules = data.schedules
  },
  setDetailSesiReschedule (state, val) {
    state.new.detail_sesi_resch.push(val)
  },
  clearDetailSesiReschedule (state, val) {
    state.new.detail_sesi_resch = []
  },
  setProjectId (state, val) {
    state.new.project_id = val
  },
  setDataDetailSurvey (state, val) {
    state.dataDetailSurvey = val
  },
  setDataDetailPenawaran (state, val) {
    state.dataDetailPenawaran = val
  },
  setDataDetailPenawaranRejected (state, val) {
    state.dataDetailPenawaranRejected = val
  },
  setDataPilihTukang (state, val) {
    state.dataPilihTukang = val
  },
  setDataFotoSurvey (state, val) {
    state.dataFotoSurvey = val
  },
  setDataJadwalPekerjaan (state, val) {
    state.dataJadwalPekerjaan = val
  },
  setDurationData (state, val) {
    state.getDurationData = val
  },
  setDataLengkapiMaterial (state, val) {
    state.dataLengkapiMaterial = val
  },
  setDataUpdateItemPekerjaanBS (state, val) {
    if (val.clearData) {
      state.dataUpdateItemPekerjaanBS = {
        surveyId: null,
        isUpdate: false,
        initCheckin: null,
        initPilihTukang: null,
        initFotoSurvey: null,
        initJadwalPekerjaan: null,
        initLengkapiMaterial: null
      }
    } else {
      state.dataUpdateItemPekerjaanBS = {
        ...state.dataUpdateItemPekerjaanBS,
        ...val
      }
    }
  },
  resetDataDetailPenawaran (state) {
    state.dataDetailPenawaran = null
  },
  setSeeCombineGlobal (state, val) {
    state.seeCombineGlobal.on = val.on
    state.seeCombineGlobal.color = val.color
    state.seeCombineGlobal.rgba = val.rgba
  },
  resetSeeCombineGlobal (state) {
    state.seeCombineGlobal.on = false
    state.seeCombineGlobal.color = null
    state.seeCombineGlobal.rgba = null
  },
  setPreviewDownloadOffering (state, val) {
    state.previewDownloadOffering = val
  },
  setDataListFilter (state, val) {
    state.dataListFilter = val
  },
  setAlertAssignTukang (state, val) {
    state.dataAlertAssignTukang = val
  },
  setScrollElement (state, val) {
    if (!val) {
      state.scrollElement = {
        selectedTab: 1,
        scroll: false,
        element: '',
        selectedDate: null
      }
    }
    state.scrollElement = val
  },
  setHeaderProject (state, val) {
    state.dataHeaderProject = val
  },
  setUpdatedItemPekerjaan (state, val) {
    state.updatedItemPekerjaan = val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
