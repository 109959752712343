<template>
  <div class="is-flex">
    <div
      v-if="showHeader"
      :style="{ width: `${widthColumnNavProps}px`, height: `${heightColumnWeeklyProps}px` }"
      class="sticky-left z-index-2 total-weekly-column side border-right-5 p-3 flex-center is-flex-direction-column"
    >
      <p class="averta-bold label-14">Total Mingguan</p>
      <p class="label-14">({{ countValueInsideArray(totalWeekProps) }} Proyek)</p>
    </div>
    <div v-if="showColumn" class="is-flex">
      <div
        v-for="(weekly, index) in totalWeekProps"
        :key="`empty-${index}`"
        :style="{ width: `${widthColumnProps}px`, height: `${heightColumnWeeklyProps}px` }"
        :class="{ 'border-right-5': index === 1 }"
        class="total-weekly-column flex-center"
      >
        <p class="averta-bold label-14">Selesai: {{weekly.completed}} <span class="label-16 text-grey-4">|</span> Belum Selesai : {{weekly.not_completed}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TotalWeeklyColumnProgress',
  props: {
    showHeader: {
      type: Boolean,
      default: false
    },
    showColumn: {
      type: Boolean,
      default: true
    },
    totalWeekProps: {
      type: Array
    },
    heightColumnWeeklyProps: {
      type: Number
    },
    widthColumnProps: {
      type: Number
    },
    widthColumnNavProps: {
      type: Number
    }
  },
  methods: {
    countValueInsideArray (arrayDaily) {
      if (!arrayDaily || arrayDaily.length < 1) {
        return 0
      }

      let total = 0
      for (let i = 0; i < arrayDaily.length; i++) {
        total += arrayDaily[i].completed
      }

      return total
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.total-weekly-column {
  border: 1px solid $color-grey-10;
  border-top-width: 0;
  border-bottom-width: 0;
  border-left-width: 0;
  background: $color-light-green-2;
}

.total-weekly-column.side {
  border-left-width: 1px;
}
</style>
