<template>
  <div v-if="isLoading || isLoadingSurvey" class="p-6 mb-6 c-w-100">
    <Skeleton v-if="isMobile" />
    <CheckinSkeleton v-else />
  </div>
  <div v-else class="px-6 py-6 pt-md-4 container h-100 w-100 body-wrapper">
    <div class="is-flex is-align-items-center is-justify-content-space-between mb-6 mb-md-5 mt-3">
      <div>
        <div class="is-flex is-align-items-center" v-if="!isEditArea">
          <ArrowLeftIcon class="mr-3 is-clickable" />
          <p class="averta-bold is-size-5 is-clickable" @click="surveyDetail()">
            Kembali ke Survey Detail
          </p>
        </div>
      </div>

      <b-button
        class="d-sm-none averta-bold px-5 is-flex is-justify-content-center mr-5 is-align-items-center"
        :class="{ 'btn-disabled': buttonDisable === true, 'btn-active' : buttonDisable === false }" @click="simpanCheckin()" :disabled="buttonDisable">
        <div class="is-flex is-align-items-center">
          <CheckIcon class="mr-3" />
          <span>Simpan</span>
        </div>
      </b-button>
    </div>
    <div class="w-100">
      <!-- Script di bawah jangan dihapus, karena untuk trigger state areas -->
      <p style="display: none;">{{ areas }}</p>
      <div class="columns">
        <div class="column is-3 border-right pb-sm-0">
          <span class="is-size-4 averta-bold">Pilih Area</span>
          <div class="area-wrapper mt-4">

            <!-- sliceArea1 -->
            <div v-if="sliceArea1.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea1" :key="`area-${index}`" class="mr-3 mb-5 mb-md-0 btn-tabs"
              :class="{ 'is-active averta-bold': area === item.area_name }" @click="changeIsActive(item)">
                <div v-if="item.isNew || item.area_id === null" class="is-flex is-align-items-center">
                  <div
                    class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column mr-3">
                    <p :class="{ 'averta-bold': area === item.area_name }">{{ item.area_name }}</p>
                    <small>Pengecekan :</small>
                  </div>
                  <div class="is-flex is-align-items-center" style="position: relative;">
                    <div class="btn-area">
                      <button class="btn-delete-detail is-clickable" @click="showEditArea(item.area_name)">
                        <EditIcon />
                      </button>
                      <button class="btn-delete-detail is-clickable" @click="deleteArea()">
                        <TrashIcon class="btn-trash" />
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else
                  class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column">
                  <p class="averta-bold">{{ item.area_name }}</p>
                  <small class="text-pengecekan">Pengecekan :</small>
                </div>
              </b-button>
            </div>

            <!-- sliceArea2 -->
            <div v-if="sliceArea2.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea2" :key="`slice-${index}`" class="mr-3 mb-5 mb-md-0 btn-tabs"
              :class="{ 'is-active averta-bold': area === item.area_name }" @click="changeIsActive(item)">
                <div v-if="item.isNew || item.area_id === null" class="is-flex is-align-items-center">
                  <div
                    class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column mr-3">
                    <p :class="{ 'averta-bold': area === item.area_name }">{{ item.area_name }}</p>
                    <small class="text-pengecekan">Pengecekan :</small>
                  </div>
                  <div class="is-flex is-align-items-center" style="position: relative;">
                    <div class="btn-area">
                      <button class="btn-delete-detail is-clickable" @click="showEditArea(item.area_name)">
                        <EditIcon />
                      </button>
                      <button class="btn-delete-detail is-clickable" @click="deleteArea()">
                        <TrashIcon class="btn-trash" />
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else
                  class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column">
                  <p class="averta-bold">{{ item.area_name }}</p>
                  <small class="text-pengecekan">Pengecekan :</small>
                </div>
              </b-button>
            </div>

            <!-- sliceArea3 -->
            <div v-if="sliceArea3.length > 0" class="slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea3" :key="`slice-${index}`" class="mr-3 mb-5 mb-md-0 btn-tabs"
              :class="{ 'is-active averta-bold': area === item.area_name }" @click="changeIsActive(item)">
                <div v-if="item.isNew || item.area_id === null" class="is-flex is-align-items-center">
                  <div
                    class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column mr-3">
                    <p :class="{ 'averta-bold': area === item.area_name }">{{ item.area_name }}</p>
                    <small class="text-pengecekan">Pengecekan :</small>
                  </div>
                  <div class="is-flex is-align-items-center" style="position: relative;">
                    <div class="btn-area">
                      <button class="btn-delete-detail is-clickable" @click="showEditArea(item.area_name)">
                        <EditIcon />
                      </button>
                      <button class="btn-delete-detail is-clickable" @click="deleteArea()">
                        <TrashIcon class="btn-trash" />
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else
                  class="white-space-normal white-space-sm-nowrap is-flex flex-direction is-flex-direction-column">
                  <p class="averta-bold">{{ item.area_name }}</p>
                  <small class="text-pengecekan">Pengecekan :</small>
                </div>
              </b-button>
            </div>

            <!-- <div class="mb-5 mb-md-0 add-area-wrapper">
              <p class="averta-bold has-text-centered color-red is-clickable" @click="isComponentModalActive = true">
                + Tambah Area
              </p>
            </div> -->
          </div>
        </div>
        <div class="column is-9 pt-sm-0">
          <span class="is-size-4 averta-bold">Pilih Bidang</span>
          <div v-for="(dataArea, indexArea) in areas" :key="`idxArea-${indexArea}`" class="mt-3">
            <div class="tabs tabs-area-wrapper thin-scroll user-select-text" v-if="area === dataArea.area_name">
              <div class="overflow-x-auto overflow-y-hidden thin-scroll tabs-component-wrapper">
                <ul v-if="sliceComponent1.length > 0">
                  <li v-for="(dataComponents, indexComponent) in sliceComponent1" :key="`idxSlice1${indexComponent}`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                    <a @click="handleClick(dataComponents.component_id)">{{dataComponents.component_name}}</a>
                  </li>
                </ul>
                <ul v-if="sliceComponent2.length > 0">
                  <li v-for="(dataComponents, indexComponent) in sliceComponent2" :key="`idxSlice2${indexComponent}`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                    <a @click="handleClick(dataComponents.component_id)">{{dataComponents.component_name}}</a>
                  </li>
                </ul>
                <ul v-if="sliceComponent3.length > 0">
                  <li v-for="(dataComponents, indexComponent) in sliceComponent3" :key="`idxSlice3${indexComponent}`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                    <a @click="handleClick(dataComponents.component_id)">{{dataComponents.component_name}}</a>
                  </li>
                </ul>
              </div>
            </div>

            <!-- content -->
            <div v-for="(dataComponents, indexComponent) in dataArea.components" :key="`${indexComponent}-content`">
              <div v-if="currentClick === dataComponents.component_id && dataArea.area_name === selectedArea.area_name">
                <div class="mt-4 body-tab-wrapper">
                  <div class="is-relative">
                    <p class="averta-bold mb-3">Tambahkan komponen yang membutuhkan perbaikan</p>
                    <section class="input-wrapper">
                      <b-field>
                        <b-autocomplete class="border-input" v-model="search" :data="filteredDataArray"
                          placeholder="Cari Komponen" icon="magnify" clearable open-on-focus
                          @select="option => selectDetailComponent(option, dataComponents.component_name)">
                          <template slot-scope="props">
                            <div class="is-flex is-justify-content-space-between py-2">
                              <div class="component-name-wrapper">
                                <p>{{ props.option.name }}</p>
                              </div>
                              <button class="btn-delete-detail">
                                <img :src="require('@/assets/img/plus-icon.png')" alt="">
                              </button>
                            </div>
                          </template>
                          <template #empty>No results found</template>
                        </b-autocomplete>
                      </b-field>
                    </section>
                  </div>
                  <div v-if="dataComponents.detail_components.length < 1" class="is-flex is-align-items-center mt-3">
                    <ListCheckIcon />
                    <p class="text-red is-italic ml-2"> Belum ada pemeriksaan yang dilakukan</p>
                  </div>

                  <div v-else v-for="(dataDetailComponent, index) in dataComponents.detail_components"
                  :key="dataComponents.step_skill_detail_component_id + '_' + index" class="mb-5 px-3 px-md-0">
                    <div class="komponen-layout p-3 px-md-0 columns mt-4">
                      <div class="column is-6 is-flex is-justify-content-space-between">
                        <div>
                          <p>Pengecekan</p>
                          <p class="averta-bold">{{ dataDetailComponent.detail_component_name }}</p>
                        </div>

                        <b-button
                          @click="deleteDetailComponent(dataDetailComponent.detail_component_name, dataComponents.component_name)"
                          class="btn-delete-detail d-md-block">
                          <img :src="require('@/assets/img/icon-delete.png')" alt="">
                        </b-button>
                      </div>
                      <div class="column is-6">
                        <p class="mb-2">Catatan</p>
                        <div class="is-flex is-align-items-center">
                          <div class="is-flex-grow-1 mr-2">
                            <b-field>
                              <b-input type="textarea"
                                :value="dataDetailComponent.note"
                                @input="(event) => addNote(dataComponents.component_name, index, event)">
                              </b-input>
                            </b-field>
                          </div>
                          <b-button
                            @click="deleteDetailComponent(dataDetailComponent.detail_component_name, dataComponents.component_name)"
                            class="btn-delete-detail d-md-none">
                            <img :src="require('@/assets/img/icon-delete.png')" alt="">
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="btn-bottom">
        <b-button class="averta-bold px-5 is-flex is-justify-content-center is-align-items-center w-100"
          :class="{ 'btn-disabled': buttonDisable === true, 'btn-active' : buttonDisable === false }" @click="simpanCheckin()" :disabled="buttonDisable">
          <div class="is-flex is-align-items-center">
            <CheckIcon class="mr-3" />
            <span>Simpan</span>
          </div>
        </b-button>
      </div>
    </div>

    <b-modal v-model="isComponentModalActive">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">Tambah Area</p>
            <button type="button" class="delete" @click="closeModalNewArea()" />
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Area</label>
              <input v-model.trim="newArea" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="addArea()"
                :class="{ 'btn-active': newArea !== '' }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="modalEditArea">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">Ubah Area</p>
            <button type="button" class="delete" @click="closeModalEditArea()" />
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Area</label>
              <input v-model.trim="stateEditArea" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5" @click="editArea()"
                :class="{ 'btn-active': stateEditArea !== '' }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="modalAddComponent">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">Tambah Komponen</p>
            <button type="button" class="delete" @click="closeModalNewComponent()" />
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Komponen</label>
              <input v-model.trim="newComponent" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5"
                @click="addNewComponent()" :class="{ 'btn-active': newComponent !== '' }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="modalEditComponent">
      <div class="modal-template">
        <div class="modal-body w-100">
          <header class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="averta-bold is-size-5">Ubah Komponen</p>
            <button type="button" class="delete" @click="closeModalEditComponent()" />
          </header>
          <div class="mt-5">
            <div class="mx-5 mb-5">
              <label class="averta-bold is-size-6">Nama Komponen</label>
              <input v-model.trim="stateEditComponent" class="input mt-2 mb-3" type="text" placeholder="Isi disini" />
            </div>
            <footer class="py-5 is-flex is-justify-content-flex-end footer-shadow">
              <b-button class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5"
                @click="editComponents()" :class="{ 'btn-active': stateEditComponent !== '' }">
                <div class="is-flex is-align-items-center">
                  <CheckIcon class="mr-3" />
                  <span>Simpan</span>
                </div>
              </b-button>
            </footer>
          </div>
        </div>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" v-model="loadingBtn" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import imageDeleteAreaComponent from '@/assets/img/delete-area-component.png'
import CheckinSkeleton from '@/components/skeleton/CheckinSkeleton'
import Skeleton from '@/components/skeleton/Skeleton'

import {
  ArrowLeftIcon,
  CheckIcon,
  EditIcon,
  TrashIcon,
  PlusWithBorder,
  ListCheckIcon,
  SpinnerIcon
} from '@/components/icons'

export default {
  name: 'CheckInPemeriksaan',
  props: ['isEditArea'],
  components: {
    CheckinSkeleton,
    Skeleton,
    ArrowLeftIcon,
    CheckIcon,
    EditIcon,
    TrashIcon,
    // eslint-disable-next-line vue/no-unused-components
    PlusWithBorder,
    // eslint-disable-next-line vue/no-unused-components
    ListCheckIcon,
    SpinnerIcon
  },
  data () {
    return {
      isComponentModalActive: false,
      modalEditArea: false,
      modalAddComponent: false,
      modalEditComponent: false,
      isLoading: false,
      isLoadingSurvey: false,
      loadingBtn: false,
      area: null,
      areas: [],
      component: null,
      selectedArea: null,
      newArea: '',
      stateEditArea: '',
      newComponent: '',
      stateEditComponent: '',
      search: '',
      detailComponent: [],
      selectedComponent: [],
      note: '',
      dataVendor: null,

      currentClick: 1,
      sliceArea1: [],
      sliceArea2: [],
      sliceArea3: [],
      sliceComponent1: [],
      sliceComponent2: [],
      sliceComponent3: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getDataUpdateItemPekerjaanBS: 'project/getDataUpdateItemPekerjaanBS'
    }),
    filteredDataArray () {
      let newSearch

      if (this.search === undefined) {
        newSearch = ''
      } else {
        newSearch = this.search
      }

      return this.detailComponent.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(newSearch.toLowerCase()) >= 0
      })
    },
    buttonDisable () {
      const validasi = []
      if (this.areas.length > 0) {
        this.areas.forEach(area => {
          area.components.forEach(component => {
            if (component.detail_components.length === 0) {
              validasi.push(0)
            } else {
              validasi.push(1)
            }
          })
        })
      }

      const isDisable = validasi.every(item => {
        return item === 0
      })

      return isDisable
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    handleClick (value) {
      this.currentClick = value
    },
    handleTestEmit () {
      this.$emit('handleEditArea', true)
    },
    changeIsActive (data) {
      this.selectedArea = data
      this.currentClick = data.components[0].component_id
      this.area = data.area_name
      this.sliceAreaOrComponent('component', data.components)
    },
    addArea () {
      if (this.newArea === '') {
        return
      }
      const temp = {
        area_id: null,
        area_name: this.newArea,
        components: [],
        isNew: true
      }
      this.areas.push(temp)
      this.newArea = ''
      this.isComponentModalActive = false
    },
    sliceAreaOrComponent (type, array) {
      const result = []
      if (array.length > 13 && array.length < 19) {
        // Bagi menjadi dua bagian
        const middleIndex = Math.ceil(array.length / 2)
        result.push(array.slice(0, middleIndex))
        result.push(array.slice(middleIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = result[1]
          this.sliceComponent3 = []
        }
      } else if (array.length >= 19) {
        // Bagi menjadi tiga bagian
        const firstSplitIndex = Math.ceil(array.length / 3)
        const secondSplitIndex = 2 * firstSplitIndex
        result.push(array.slice(0, firstSplitIndex))
        result.push(array.slice(firstSplitIndex, secondSplitIndex))
        result.push(array.slice(secondSplitIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = result[2]
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = result[1]
          this.sliceComponent3 = result[2]
        }
      } else {
        // Jika kurang dari atau sama dengan 13, tidak perlu membagi
        result.push(array)
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = []
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = []
          this.sliceComponent3 = []
        }
      }
    },
    getDataCheckinPemeriksaan () {
      this.isLoading = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId
      }

      this.$store
        .dispatch('project/getCheckinPemeriksaan', payload)
        .then(response => {
          this.areas = response.data.data
          this.area = response.data.data[0].area_name
          this.selectedArea = this.areas[0]
          this.currentClick = this.areas[0].components[0].component_id
          this.sliceAreaOrComponent('area', this.areas)
          this.sliceAreaOrComponent('component', this.areas[0].components)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          if (error.response.data.code === 0 && error.response.data.message === 'Riwayat pemeriksaan tidak ditemukan') {
            this.$store
              .dispatch('project/getDataAreaMaster', payload)
              .then(response => {
                this.areas = response.data.data
                this.area = this.areas[0].area_name
                this.selectedArea = this.areas[0]
                this.sliceAreaOrComponent('area', this.areas)
                this.sliceAreaOrComponent('component', this.areas[0].components)
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.$buefy.toast.open({
              message:
                'Gagal memuat data checkin pemeriksaan, silahkan coba lagi',
              type: 'is-danger'
            })
          }
        })
    },
    surveyDetail () {
      this.$router.push(`/project/survey/${this.$route.params.surveyId}/detail`)
    },
    getDataDetailComponent () {
      const payload = {
        url: this.url
      }

      if (this.dataVendor && this.dataVendor.id) {
        payload.vendor_id = this.dataVendor.id
      }

      this.$store
        .dispatch('project/getDetailComponents', payload)
        .then(response => {
          this.detailComponent = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.$buefy.toast.open({
            message:
              'Gagal memuat data detail component, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    selectDetailComponent (data, nameComponent) {
      this.search = ''
      const lainLain = data.name.toLowerCase().includes('lain-lain')
      const findArea = this.areas.find(item => item.area_name === this.area)
      const findComponent = findArea.components.find(item => item.component_name === nameComponent)

      // const findDetailComponent = findComponent.detail_components.find(item => {
      //   return !lainLain && item.detail_component_name === data.name
      // })

      const addDetailComponent = {
        id: data.id,
        detail_component_name: data.name,
        note: ''
        // custom_id: Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000
      }

      if (lainLain) {
        addDetailComponent.custom_id = Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000
      }

      findComponent.detail_components.push(addDetailComponent)
      return this.$buefy.toast.open({
        message: 'Berhasil input data detail component',
        type: 'is-success'
      })

      // if (findDetailComponent) {
      //   return this.$buefy.toast.open({
      //     message:
      //       'Maaf data detail component sudah dimasukkan sebelumnya',
      //     type: 'is-danger'
      //   })
      // } else {
      //   const addDetailComponent = {
      //     id: data.id,
      //     detail_component_name: data.name,
      //     note: ''
      //   }
      //   findComponent.detail_components.push(addDetailComponent)
      //   return this.$buefy.toast.open({
      //     message:
      //       'Berhasil input data detail component',
      //     type: 'is-success'
      //   })
      // }
    },
    addNewComponent () {
      const findArea = this.areas.find(item => item.area_name === this.area)
      const findComponent = findArea.components.find(item => item.component_name.toLowerCase() === this.newComponent.toLowerCase())
      if (findComponent) {
        return this.$buefy.toast.open({
          message:
            'Maaf data component sudah dimasukkan sebelumnya',
          type: 'is-danger'
        })
      } else {
        const addComponent = {
          component_id: null,
          component_name: this.newComponent,
          detail_components: []
        }

        findArea.components.push(addComponent)
        this.modalAddComponent = false
        this.newComponent = ''
        return this.$buefy.toast.open({
          message:
            'Berhasil tambah data area',
          type: 'is-success'
        })
      }
    },
    closeModalNewComponent () {
      this.modalAddComponent = false
      this.newComponent = ''
    },
    closeModalNewArea () {
      this.isComponentModalActive = false
      this.newArea = ''
    },
    closeModalEditArea () {
      this.modalEditArea = false
      this.stateEditArea = ''
    },
    closeModalEditComponent () {
      this.modalEditComponent = false
      this.stateEditComponent = ''
    },
    deleteDetailComponent (detailComponentName, componentName) {
      this.$swal({
        imageUrl: imageDeleteAreaComponent,
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Pengecekan Ini?</p>',
        html: '<p class="averta-reguler"> Seluruh data pengecekan ini akan terhapus.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const findArea = this.areas.find(item => item.area_name === this.area)
          const findComponent = findArea.components.find(item => item.component_name === componentName)
          const findDetailComponent = findComponent.detail_components.findIndex(item => item.detail_component_name === detailComponentName)
          if (findDetailComponent >= 0) {
            findComponent.detail_components.splice(findDetailComponent, 1)
            return this.$buefy.toast.open({
              message:
                'Berhasil hapus detail komponen',
              type: 'is-success'
            })
          }
        }
      })
    },
    deleteComponents (componentName) {
      this.$swal({
        imageUrl: imageDeleteAreaComponent,
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Komponen Ini?</p>',
        html: '<p>Seluruh informasi dalam komponen tersebut akan terhapus</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus Komponen',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const findArea = this.areas.find(item => item.area_name === this.area)
          const findComponent = findArea.components.findIndex(item => item.component_name === componentName)
          if (findComponent >= 0) {
            findArea.components.splice(findComponent, 1)
            return this.$buefy.toast.open({
              message:
                'Berhasil hapus komponen',
              type: 'is-success'
            })
          }
        }
      })
    },
    deleteArea () {
      this.$swal({
        imageUrl: imageDeleteAreaComponent,
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Area Ini?</p>',
        html: '<p>Seluruh informasi dalam area tersebut akan terhapus</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus Area',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const findArea = this.areas.findIndex(item => item.area_name === this.area)
          if (findArea >= 0) {
            this.areas.splice(findArea, 1)
            return this.$buefy.toast.open({
              message:
                'Berhasil hapus area',
              type: 'is-success'
            })
          }
        }
      })
    },
    showEditArea (selectedArea) {
      this.stateEditArea = selectedArea
      this.modalEditArea = true
    },
    editArea () {
      const findArea = this.areas.find(item => item.area_name === this.area)
      if (findArea) {
        findArea.area_name = this.stateEditArea
        this.area = this.stateEditArea
        this.modalEditArea = false
        return this.$buefy.toast.open({
          message:
            'Berhasil edit area',
          type: 'is-success'
        })
      }
    },
    showEditComponents (selectedComponent) {
      this.stateEditComponent = selectedComponent
      this.component = selectedComponent
      this.modalEditComponent = true
    },
    editComponents () {
      const findArea = this.areas.find(item => item.area_name === this.area)
      const findComponent = findArea.components.find(item => item.component_name === this.component)
      if (findComponent) {
        findComponent.component_name = this.stateEditComponent
        this.modalEditComponent = false
        return this.$buefy.toast.open({
          message:
            'Berhasil edit component',
          type: 'is-success'
        })
      }
    },
    // addNote (detailComponentName, componentName, index) {
    //   const findArea = this.areas.find(item => item.area_name === this.area)
    //   const findComponent = findArea.components.find(item => item.component_name === componentName)
    //   const findDetailComponent = findComponent.detail_components.find(item => item.detail_component_name === detailComponentName)
    //   if (findDetailComponent) {
    //     findDetailComponent.note = this.$refs['note_' + this.area + '_' + componentName + '_' + index][0].newValue
    //   }
    // },
    addNote (componentName, index, newValue) {
      const findArea = this.areas.find(item => item.area_name === this.area)
      if (!findArea) return

      const findComponent = findArea.components.find(item => item.component_name === componentName)
      if (!findComponent) return

      // Update data using the index directly
      if (findComponent.detail_components[index]) {
        findComponent.detail_components[index].note = newValue || ''
      }
    },
    simpanCheckin () {
      this.loadingBtn = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        checkin: this.areas,
        latest_step: 1
      }

      this.$store
        .dispatch('project/postDataCheckin', payload)
        .then(response => {
          this.loadingBtn = false
          this.$buefy.toast.open({
            message:
              'Sukses simpan data checkin',
            type: 'is-success'
          })
          if (this.isEditArea) {
            this.$emit('handleEditArea', true)
          } else {
            this.surveyDetail()
          }
        })
        .catch(error => {
          this.loadingBtn = false
          console.log(error)
          const message = error.response.data.message
          this.$buefy.toast.open({
            message: message || 'Gagal simpan data checkin, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getDetailSurvey () {
      this.isLoadingSurvey = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId || this.getDataUpdateItemPekerjaanBS.surveyId
      }
      this.$store
        .dispatch('project/getDetailSurvey', payload)
        .then((response) => {
          const res = response.data.data
          this.dataVendor = res.vendor
          this.isLoadingSurvey = false
          this.getDataDetailComponent()
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingSurvey = false
        })
    }
  },
  created () {
    this.getDataCheckinPemeriksaan()
    this.getDetailSurvey()
  },
  mounted () {
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 85vh;
}

.d-md-block {
  display: none;
}

.white-space-normal {
  white-space: normal;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.color-red {
  color: #d9272d;
}

.modal-template {
  width: 35em;
  margin: auto;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}

.input-wrapper {
  width: 60%;
}

.border-input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.komponen-layout {
  background: #fafafa;
  border-radius: 12px;
}

.btn-delete-detail {
  border: none;
  background: none;
}

.btn-area {
  position: relative;
  z-index: 2;
  margin-right: -10px;
}

.btn-trash {
  height: 19px;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  overflow: auto;
}

.style-textarea {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
}

.btn-bottom {
  display: none !important;
}

.component-name-wrapper {
  width: 90%;
  white-space: normal;
}

.text-pengecekan {
  margin-top: -2px;
}

@media only screen and (max-width: 768px) {
  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-5 {
    margin-bottom: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .modal-template {
    width: 100%;
    height: 25em;
    padding: 0 20px;
  }

  .modal-template header {
    height: 4em;
    color: white;
    padding: 0 20px;
  }

  .input-wrapper {
    width: 100%;
  }

  .tab-content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tab-content-wrapper .b-tabs .tab-content {
    padding: 0 !important;
  }

  .body-tab-wrapper {
    position: relative;
    min-height: 60vh;
  }

  .b-tabs .tab-content {
    padding: 0 !important;
  }

  .d-md-none {
    display: none;
  }

  .d-md-block {
    display: block;
  }

  .area-wrapper {
    flex-direction: row;
    white-space: nowrap;
    overflow-x: scroll;
    flex-wrap: wrap;
  }

  .area-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .area-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .add-area-wrapper {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
  }

  .border-right {
    border: none;
  }

  .white-space-sm-nowrap {
    white-space: nowrap;
  }

  .body-wrapper {
    width: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .btn-tabs {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .btn-bottom {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .d-sm-none {
    display: none !important;
  }

  .btn-bottom {
    opacity: 1 !important;
    display: flex !important;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    padding: 1rem;
    box-shadow: 0px -3px 30px rgba(0, 0, 0, 0.07);
  }

  .btn-tabs {
    height: 3.5em;
  }
}
</style>
