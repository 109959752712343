<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1283_125650"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="14"
      height="14"
    >
      <path
        d="M3.54157 4.6737C5.2249 6.83203 8.33324 10.832 8.33324 10.832V15.832C8.33324 16.2904 8.70824 16.6654 9.16657 16.6654H10.8332C11.2916 16.6654 11.6666 16.2904 11.6666 15.832V10.832C11.6666 10.832 14.7666 6.83203 16.4499 4.6737C16.8749 4.1237 16.4832 3.33203 15.7916 3.33203H4.1999C3.50824 3.33203 3.11657 4.1237 3.54157 4.6737Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1283_125650)">
      <rect width="20" height="20" :fill="color" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VendorFilter',
  props: {
    color: {
      type: String,
      default: '#252525'
    },
    size: {
      type: String,
      default: '20'
    }
  }
}
</script>
