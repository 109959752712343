<template>
  <div class="min-h-90vh c-w-calculate mt-5">
    <div class="px-5">
      <div class="is-inline-flex is-align-items-center mb-5">
        <SearchLongIcon />
        <p class="label-18 averta-bold ml-2">Cari Lebih Spesifik</p>
      </div>
      <FormSpecificSearch class="mb-3" />
    </div>

    <div class="body-specific-search px-5">
      <div v-if="!data || Object.keys(data).length < 1 || data.length < 1" class="empty-search-wrapper">
        <img :src="require('@/assets/img/searching-1.png')" alt="" />
        <p class="averta-black mt-5">Tentukan Kata Kunci Pencarian</p>
      </div>

      <div v-else>
        <p class="averta-black label-18 mb-4 mt-5">
          Menampilkan <span class="count-result-data">{{ data.length }}</span> Hasil Pencarian Sesuai
        </p>

        <!-- List Project -->
        <div class="list-project" v-if="!isLoading && data">
          <div v-for="(item, index) in data" :key="`search-${index}`">
            <NewCardProject
              :data="item"
              @showModalProyekDarurat="showProyekDarurat($event)"
              @lihatCatatanProyek="showCatatanProyek($event)"
              @showModalInvoiceTagihan="showInvoiceTagihan($event)"
              @showModalTanggalPenagihan="showTanggalPenagihan($event)"
              @showModalBeforeAfter="showModalBeforeAfter($event)"
              @detailProject="detailProject($event)"
              @reloadList="reloadList"
            />
          </div>
        </div>
        <!-- List Project -->

        <!-- Modal Proyek Darurat -->
        <ModalProyekDarurat
          :dataModalUrgent="projectUrgent"
          :surveyId="surveyIdDarurat"
          :showModal="modalProyekDarurat"
          :width="isMobile ? '95vw' : '45vw'"
          @closeModal="showProyekDarurat"
          :loadData="reloadList"
        />
        <!-- Modal Proyek Darurat -->

        <!-- Modal Lihat Catatan Proyek -->
        <ModalHistoryCatatanProyek
          :showModal="modalHistoryCatatanProyek"
          width="95vw"
          @closeModal="showCatatanProyek"
          :surveyId="surveyId"
        />
        <!-- Modal Lihat Catatan Proyek -->

        <!-- Modal Invoice Tagihan -->
        <ModalInvoiceTagihan
          :data="dataInvoice"
          :showModal="modalInvoiceTagihan"
          :width="isMobile ? '95vw' : '40vw'"
          @closeModal="showInvoiceTagihan"
          @reloadList="reloadList"
        />
        <!-- Modal Invoice Tagihan -->

        <!-- Modal Tanggal Penagihan -->
        <ModalTanggalPenagihan
          :data="dataPenagihan"
          :showModal="modalTanggalPenagihan"
          :width="isMobile ? '95vw' : '40vw'"
          @closeModal="showTanggalPenagihan"
          @reloadList="reloadList"
        />
        <!-- Modal Tanggal Penagihan -->

        <!-- Sidebar Before After -->
        <SidebarBeforeAfter
          :showSidebar="modalBeforeAfter"
          :data="dataModalBeforeAfter"
          type="list"
          @closeSidebar="closeSidebarBeforeAfter"
        />
        <!-- Sidebar Before After -->
      </div>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage } from '@/helpers'
import { SUMMARECON_MTOWN } from '@/helpers/constants'

export default {
  name: 'SpecificSearch',
  components: {
    FormSpecificSearch: () => import('@/components/forms/FormSpecificSearch'),
    SearchLongIcon: () => import('@/components/icons/SearchLongIcon'),
    NewCardProject: () => import('@/components/project/NewCardProject'),
    ModalProyekDarurat: () => import('@/components/modal/ModalProyekDarurat'),
    ModalHistoryCatatanProyek: () => import('@/components/modal/ModalHistoryCatatanProyek'),
    ModalInvoiceTagihan: () => import('@/components/modal/ModalInvoiceTagihan'),
    ModalTanggalPenagihan: () => import('@/components/modal/ModalTanggalPenagihan'),
    SidebarBeforeAfter: () => import('@/components/SidebarBeforeAfter'),
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon')
  },
  data () {
    return {
      surveyId: null,
      isLoading: false,
      data: [],
      dataInvoice: null,
      dataPenagihan: null,
      dataModalBeforeAfter: null,

      modalProyekDarurat: false,
      modalHistoryCatatanProyek: false,
      modalInvoiceTagihan: false,
      modalTanggalPenagihan: false,
      modalBeforeAfter: false,

      projectUrgent: null,
      surveyIdDarurat: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      getSpecificSearch: 'projectV2/getSpecificSearch'
    }),
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    }
  },
  methods: {
    showProyekDarurat (data) {
      if (data) {
        this.projectUrgent = data.projectUrgent
        this.surveyIdDarurat = data.surveyId
      }
      this.modalProyekDarurat = !this.modalProyekDarurat
    },
    showCatatanProyek (event) {
      this.surveyId = event
      this.modalHistoryCatatanProyek = !this.modalHistoryCatatanProyek
    },
    showInvoiceTagihan (data) {
      if (data) {
        this.dataInvoice = data
      }
      this.modalInvoiceTagihan = !this.modalInvoiceTagihan
    },
    showTanggalPenagihan (data) {
      if (data) {
        this.dataPenagihan = data
      }
      this.modalTanggalPenagihan = !this.modalTanggalPenagihan
    },
    showModalBeforeAfter (data) {
      this.modalBeforeAfter = true
      this.dataModalBeforeAfter = data
    },
    closeSidebarBeforeAfter () {
      this.modalBeforeAfter = false
      this.dataModalBeforeAfter = null
    },
    detailProject (data) {
      const currentRole = this.user.roles[0].name

      if (data.project_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/${data.project_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/${data.project_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/${data.project_id}/detail`
            break
          case 'Purchasing':
            routePath = `/purchasing/project/${data.project_id}/detail`
            break
          default:
            break
        }

        this.$router.push({ path: routePath, query: { fromSearch: true } })
      } else if (data.offering_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/survey/${data.survey_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/survey/${data.survey_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/survey/${data.survey_id}/detail`
            break
          case 'Purchasing':
            routePath = `/purchasing/project/offering/${data.offering_id}/detail`
            break
          default:
            break
        }
        this.$router.push({ path: routePath, query: { fromSearch: true } })
      } else if (data.survey_id) {
        let routePath = ''

        switch (currentRole) {
          case 'Konsultan Survey':
            routePath = `/project/survey/${data.survey_id}/detail`
            break
          case 'Site Manager':
            routePath = `/sm/project/survey/${data.survey_id}/detail`
            break
          case 'Account Manager':
            routePath = `/am/project/survey/${data.survey_id}/detail`
            break
          default:
            break
        }
        this.$router.push({ path: routePath, query: { fromSearch: true } })
      }
    },
    reloadList () {
      this.modalProyekDarurat = false
      this.modalInvoiceTagihan = false
      this.modalTanggalPenagihan = false
      setTimeout(() => {
        this.handleSearch()
      }, 200)
    },
    handleSearch () {
      if (!this.keyword) {
        return alertErrorMessage('Masukan Kata Kunci')
      }
      this.getSearchResult()
    },
    getSearchResult () {
      this.isLoading = true

      let keyword = ''
      let selectedClient = ''
      let selectedArea = ''
      let selectedUnit = ''
      let selectedBuilding = ''
      let selectedStartDate = ''
      let selectedEndDate = ''
      let selectedTypeStatus = ''
      let selectedStatus = ''

      const S = this.getSpecificSearch
      if (S && (S.keyword || S.client || S.area || S.unit || S.building || S.start_date || S.end_date || S.status)) {
        keyword = S.keyword || ''
        selectedClient = S.client ? S.client.name : ''
        selectedStartDate = S.start_date ? moment(S.start_date).format('YYYY-MM-DD') : ''
        selectedEndDate = S.end_date ? moment(S.end_date).format('YYYY-MM-DD') : ''
        selectedTypeStatus = S.type || ''
        selectedStatus = S.status || ''
        selectedUnit = (S.unit && S.unit.id) || ''
        selectedBuilding = (S.unit && S.building.id) || ''

        if (S.client && S.client.code === SUMMARECON_MTOWN && S.area) {
          selectedArea = S.area.id || ''
        }
      }

      const payload = {
        url: this.url,
        keyword,
        vendor: selectedClient,
        start_date: selectedStartDate,
        end_date: selectedEndDate,
        type: selectedTypeStatus,
        status: selectedStatus,
        branch_id: selectedUnit,
        outlet_id: selectedBuilding
      }

      if (selectedArea) {
        payload.area_id = selectedArea
      }

      this.$store
        .dispatch('projectV2/searchListGlobal', payload)
        .then(response => {
          this.data = response.data.data

          window.scrollTo({ top: 930, behavior: 'smooth' })
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    }
  },
  created () {
    const isEmpty = Object.values(this.getSpecificSearch).every(
      value => value === null || value === '' || value === undefined
    )

    if (!isEmpty) {
      this.getSearchResult()
    }
  },
  watch: {
    getSpecificSearch: {
      handler (val) {
        this.getSearchResult()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.body-specific-search {
  border-top: 6px solid #e1e1e1;
  margin-top: 3em;
  padding-top: 1em;
  margin-bottom: 3em;
}

.empty-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20em;
}

.count-result-data {
  min-width: 30px;
  height: 30px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 15px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #E10009;
}
</style>
