<template>
  <div class="is-flex is-flex-direction-column gap-6 content-add-edit-client">
    <p class="label-20 averta-black">
      {{ getTypeSidebar === 'edit' ? 'Edit Client' : 'Buat Client Baru' }}
    </p>
    <div class="border-2 border-radius-20 overflow-hidden p-5 bg-grey-15">
      <p class="label-20 averta-black">Informasi Perusahaan</p>
      <p>Silakan masukkan informasi perusahaan dibawah yang ingin didaftarkan</p>
      <div class="mt-5">
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama PT <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.company_name"
            placeholder="Masukkan nama PT"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama Brand <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.name"
            placeholder="Masukkan nama brand"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Logo <span class="color-red">*</span></p>
          </div>
          <div class="flex-center-vertical">
            <label for="input-file" class="flex-center-vertical">
              <div class="img-kanggox mr-3">
                <img :src="require('@/assets/img/kanggox.png')" alt="" />
              </div>
              <div v-if="formClient.logo" class="is-relative">
                <div class="preview-crop-img is-clickable">
                  <img :src="formClient.logo" alt="" />
                </div>
                <div class="edit-image">
                  <EditIcon />
                </div>
              </div>
              <div v-else class="flex-center-vertical is-clickable input-search-1 bg-grey-3">
                <UploadLightIcon />
                <p class="ml-1">Upload Foto</p>
              </div>
            </label>
            <input
              type="file"
              id="input-file"
              class="d-none"
              ref="clientImg"
              @change="uploadImageFile"
              hidden
              accept="image/png, image/jpeg"
            />
          </div>
        </div>

        <div class="flex-center-vertical mb-4 c-w-100">
          <div class="input-label">
            <p>Sesi Survey <span class="color-red">*</span></p>
          </div>
          <b-dropdown expanded class="c-w-85" scrollable max-height="365px" append-to-body>
            <template #trigger>
              <b-input
                v-model="selectedSurveyName"
                placeholder="Pilih sesi survey yang dapat dipilih oleh client ini"
                class="input-search-2 input-none c-w-100"
                readonly
              >
              </b-input>
            </template>

            <div
              class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start p-5 gap-6 c-w-100"
              style="overflow-x: hidden"
            >
              <div
                class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100"
              >
                <p class="averta-bold">Daftar Sesi Survey</p>
                <button
                  v-if="selectedSurveyName"
                  class="button-unselect"
                  @click="clearSelectedSurvey"
                >
                  <p>Clear Select</p>
                </button>
              </div>

              <div
                v-for="(listDataSurvey, listDataSurveyIndex) in dataListSurvey"
                :key="listDataSurveyIndex"
                class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start gap-5"
              >
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center"
                >
                  <b-field>
                    <b-radio
                      v-model="selectedSurveyId"
                      :native-value="listDataSurvey.id"
                      @input="selectSurvey(listDataSurvey)"
                    >
                      <p class="averta-bold" type="is-danger">{{ listDataSurvey.name }}</p>
                    </b-radio>
                  </b-field>
                </div>
                <div
                  class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3 is-flex-wrap-wrap"
                >
                  <b-button
                    v-for="(session, buttonIndex) in listDataSurvey.sessions"
                    :key="buttonIndex"
                    size="is-small"
                    class="button-select-sesi"
                    :class="{ 'is-selected': selectedSurveyId === listDataSurvey.id }"
                  >
                    {{ session.name }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-dropdown>
        </div>

        <ModalCropImage
          :showModal="showModalCropImage"
          :previewImage="preview_crop"
          :closeModal="closeModalCropImage"
          @cropImage="cropImage($event)"
        />
      </div>
    </div>

    <div
      class="border-2 border-radius-20 overflow-hidden p-5 bg-grey-15 is-flex is-flex-direction-column gap-5"
    >
      <div class="is-flex is-flex-direction-column">
        <p class="label-20 averta-black">Daftar SM di Client ini</p>
        <p>Silakan masukkan SM yang bertanggung jawah di client ini</p>
      </div>

      <div class="is-flex is-flex-direction-column">
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Site Manager (SM) <span class="color-red">*</span></p>
          </div>
          <b-dropdown
            aria-role="menu"
            expanded
            append-to-body
            class="c-w-85"
            position="is-top-right"
            max-height="300"
            :close-on-click="false"
          >
            <template #trigger>
              <b-input
                :value="selectedNameSiteManager"
                placeholder="Pilih site manager"
                class="input-search-2 c-w-100 input-none"
                readonly
              ></b-input>
            </template>

            <!-- Konten dropdown -->
            <div class="is-flex is-flex-direction-column c-w-100 c-h-100 p-4 gap-2">
              <!-- Bagian header dropdown -->
              <div class="is-flex is-flex-direction-column c-w-100 gap-3" style="z-index: 99">
                <div
                  class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start"
                >
                  <p class="averta-bold label-18">Pilih Site Manager</p>
                </div>
                <div
                  class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start"
                >
                  <b-input
                    v-model="searchQuery"
                    placeholder="Cari"
                    class="input-search-2 c-w-100 input-none"
                  ></b-input>
                </div>
              </div>

              <!-- Daftar pilihan SM -->
              <div
                class="is-flex is-flex-direction-column c-w-100 gap-2"
                style="overflow-y: auto; max-height: 250px; max-width: 100%"
              >
                <b-dropdown-item
                  v-for="(item, index) in filteredSiteManagers"
                  :key="index"
                  :value="item.id"
                  aria-role="listitem"
                  class="c-w-100 costume-dropdown-item-create-client p-0 m-0 px-4"
                >
                  <b-checkbox
                    v-model="item.selected"
                    size="is-medium"
                    type="is-danger"
                    class="is-flex is-flex-direction-row c-w-100 c-h-100"
                    style="min-height: 70px !important"
                  >
                    <div
                      class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center gap-2"
                    >
                      <div class="avatar-image">
                        <img
                          v-if="item.photo === null"
                          src="https://nccf.in/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-19-at-5.58.49-PM-1.jpeg"
                          alt="avatar"
                        />
                        <img v-else :src="item.photo" alt="avatar" />
                      </div>
                      <p class="averta-bold color-black">{{ item.name }}</p>
                    </div>
                  </b-checkbox>
                </b-dropdown-item>
              </div>
            </div>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObjectsSimilar } from '@/helpers'
import { UploadLightIcon, EditIcon } from '@/components/icons'
import ModalCropImage from '../client-components/ModalCropImage.vue'

export default {
  name: 'ContentAddEditClient',
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    changeTypeContent: {
      type: Function
    },
    getDataListSurvey: {
      type: Array,
      default: () => []
    },
    getDataSiteManager: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadLightIcon,
    EditIcon,
    ModalCropImage
  },
  data () {
    return {
      typeSidebar: this.getTypeSidebar,
      showModalCropImage: false,
      initialFormCompare: null,

      preview_crop: null,
      formClient: {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null,
        getSelectDataIdSurvey: '',
        session: null,
        site_managers: []
      },
      selectedDataSurvey: [],
      selectedDataProject: [],
      selectedSurveyId: null,
      selectedSurveyName: '',
      dataListSurvey: this.getDataListSurvey,

      searchQuery: '',
      site_managers: [],
      dataListSiteManager: []
    }
  },
  computed: {
    ...mapGetters({
      getPayloadClient: 'client/getPayloadClient',
      getPayloadDataFormClient: 'client/getPayloadDataFormClient',
      getTypeSidebar: 'client/getClientTypeSidebar',
      getShowSidebar: 'client/getClientShowSidebar',
      getSessionList: 'session/sessionList'
    }),
    filteredSiteManagers () {
      return this.formClient.site_managers.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    },
    selectedNameSiteManager () {
      const tempSM = this.formClient.site_managers.filter((item) => item.selected)
      return tempSM.map((item) => item.name).join(', ')
    }
  },
  methods: {
    closeModalCropImage () {
      this.showModalCropImage = false
    },
    cropImage (result) {
      const { preview, file } = result

      this.formClient.logo = preview
      this.formClient.imgFile = file
      this.showModalCropImage = false
    },
    uploadImageFile (e) {
      e.preventDefault()
      if (e.target.files[0]) {
        this.preview_crop = URL.createObjectURL(e.target.files[0])
        this.showModalCropImage = true
        this.$refs.clientImg.value = ''
      }
    },
    initialForm (data) {
      const {
        id,
        // eslint-disable-next-line camelcase
        company_name = '',
        name,
        logo,
        imgFile,
        getSelectDataIdSurvey,
        session,
        // eslint-disable-next-line camelcase
        site_managers = []
      } = data

      this.formClient = {
        id,
        company_name,
        name,
        logo,
        imgFile,
        session,
        getSelectDataIdSurvey,
        site_managers
      }
    },
    handleResetForm () {
      this.preview_crop = null
      this.formClient = {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null,
        session: null,
        getSelectDataIdSurvey: '',
        site_managers: []
      }
      this.clearSelectedSurvey()
    },

    selectSurvey (survey) {
      this.selectedSurveyId = survey.id
      this.selectedSurveyName = survey.name
      this.getSelectDataIdSurvey = this.selectedSurveyId
      this.formClient.getSelectDataIdSurvey = this.selectedSurveyId
      this.updateSelectedDataSurvey()
    },
    clearSelectedSurvey () {
      this.selectedSurveyId = null
      this.selectedSurveyName = ''
      this.formClient.getSelectDataIdSurvey = undefined
    },
    updateSelectedDataSurvey () {
      const selectedSurvey = this.dataListSurvey.find(
        (survey) => survey.id === this.selectedSurveyId
      )
      if (selectedSurvey) {
        this.selectedDataSurvey = [
          {
            id: selectedSurvey.id,
            name: selectedSurvey.name,
            sessions: selectedSurvey.sessions.map((session) => ({
              name: session.name,
              start_time: session.start_time,
              end_time: session.end_time
            }))
          }
        ]
      } else {
        this.selectedDataSurvey = []
      }
    }
  },
  created () {
    this.typeSidebar = this.getTypeSidebar
    if (this.getPayloadDataFormClient) {
      const { session } = this.getPayloadDataFormClient
      // SESI SURVEY
      if (session && session.survey) {
        const selectedSurvey = session.survey
        this.dataListSurvey = this.getDataListSurvey
        if (selectedSurvey.length > 0) {
          this.selectSurvey(selectedSurvey[0])
        }
      }
    }
    this.initialForm(this.getPayloadDataFormClient)
    this.initialFormCompare = JSON.parse(JSON.stringify(this.getPayloadDataFormClient))
  },
  watch: {
    getDataListSurvey (value) {
      if (this.getTypeSidebar !== 'edit') {
        this.dataListSurvey = value
      }
    },
    formClient: {
      handler (value) {
        const {
          // eslint-disable-next-line camelcase
          company_name,
          name,
          logo,
          imgFile,
          id,
          getSelectDataIdSurvey,
          session,
          // eslint-disable-next-line camelcase
          site_managers = []
        } = value

        const body = {
          company_name,
          name,
          logo,
          imgFile,
          id,
          session,
          getSelectDataIdSurvey,
          site_managers: site_managers.filter((item) => item.selected)
        }

        const isEmptyForm = Object.entries(body).some(
          ([key, value]) =>
            key !== 'id' &&
            key !== 'session' &&
            key !== 'imgFile' &&
            (value === null ||
              value === '' ||
              value === undefined ||
              (Array.isArray(value) && value.length < 1))
        )

        let isSimilar = false
        if (this.getTypeSidebar === 'edit') {
          const bodyInitial = {
            company_name: this.initialFormCompare.company_name,
            name: this.initialFormCompare.name,
            logo: this.initialFormCompare.logo,
            imgFile: this.initialFormCompare.imgFile,
            id: this.initialFormCompare.id,
            session: this.initialFormCompare.session,
            getSelectDataIdSurvey: this.initialFormCompare.getSelectDataIdSurvey,
            site_managers: this.initialFormCompare.site_managers.filter((item) => item.selected)
          }

          isSimilar = isObjectsSimilar(bodyInitial, body)
        }
        this.$store.commit('client/setPayloadClient', {
          dataForm: body,
          isEmptyForm: isEmptyForm || isSimilar
        })
      },
      deep: true
    },
    getTypeSidebar (value) {
      this.typeSidebar = value
    },
    getShowSidebar (value) {
      if (!value) {
        this.handleResetForm()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.input-label {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.img-kanggox,
.preview-crop-img {
  height: 40px;
}

.img-kanggox img,
.preview-crop-img img {
  height: 100%;
  object-fit: contain;
}

.edit-image {
  position: absolute;
  right: -15px;
  top: 20px;
}

.is-selected {
  background-color: #fce5e6 !important; /* Warna biru */
  color: #2f2f2f !important;
}

.button-select-sesi {
  border-radius: 10px !important;
  background: #f5f5f5;
  color: #2f2f2f !important;
  width: 125px;
  height: 35px;
}

.button-unselect {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  border-radius: 5px;
  background: #e10009;
  color: #ffffff !important;
  border: none !important;
  font-weight: 700;
  margin-left: auto;
  cursor: pointer !important;
}

.avatar-image {
  display: flex;
  flex-direction: row;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.content-add-edit-client {
  margin-bottom: 6em;
}
</style>

<style>
.costume-dropdown-item-create-client.dropdown-item.is-active {
  background-color: whitesmoke !important;
}
</style>
