import { render, staticRenderFns } from "./DetailSurvey.vue?vue&type=template&id=24915e5f&scoped=true"
import script from "./DetailSurvey.vue?vue&type=script&lang=js"
export * from "./DetailSurvey.vue?vue&type=script&lang=js"
import style0 from "./DetailSurvey.vue?vue&type=style&index=0&id=24915e5f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24915e5f",
  null
  
)

export default component.exports