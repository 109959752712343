<template>
  <div>
    <div class="flex-center-vertical is-justify-content-space-between mb-3">
      <TitleHeader
        from="report-progress"
        title="Report Progress"
        desc="Ringkasan progress setiap proyek dan survey"
      />
    </div>

    <div class="flex-center-vertical is-justify-content-space-between c-w-100">
      <div>
        <p v-if="!isLittleScroll" class="mb-1 label-12">Cari Berdasarkan</p>
        <b-input
          v-model="payloadSurveyId"
          type="number"
          icon="magnify"
          placeholder="ID"
          class="input-search-2 input-none border-radius-5 adjust-padding-input"
        ></b-input>
      </div>

      <div class="is-flex">
        <DropdownClients
          class="mr-3"
          :isLittleScroll="isLittleScroll"
          @selected="updateSelection"
        />
        <DropdownStores
          :isLittleScroll="isLittleScroll"
          @selected="updateSelection"
        />
        <DropdownFilterDate
          :isLittleScroll="isLittleScroll"
          class="mr-3"
          @selected="updateSelection"
        />
        <DropdownStatus
          width="230px"
          widthFilter="37em"
          titleDropdown="Filter Status"
          placeholder="Semua Status"
          class="mr-3"
          classTitle="label-12"
          classInput="border-radius-5"
          :isReguler="true"
          :showFilterIcon="true"
          :selectedStatusProps="payloadStatus"
          :hideIconDropdown="true"
          :isLittleScroll="isLittleScroll"
          :removedStatus="[{ type: 'survey', status: 'cancel' }]"
          @handleSelectItem="updateSelection"
        />
        <ButtonApp
          height="38px"
          class="is-align-self-flex-end mb-1"
          @click="handleFilter"
        >
          <p class="averta-bold">Terapkan</p>
        </ButtonApp>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderProgress',
  components: {
    TitleHeader: () => import('./TitleHeader.vue'),
    DropdownFilterDate: () =>
      import('@/components/dropdown/DropdownFilterDate.vue'),
    DropdownClients: () => import('@/components/dropdown/DropdownClients.vue'),
    DropdownStores: () => import('@/components/dropdown/DropdownStores.vue'),
    DropdownStatus: () => import('@/components/dropdown/DropdownStatus.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue')
  },
  props: {
    loadAPI: {
      type: Function
    },
    isLittleScrollProps: {
      type: Boolean
    }
  },
  data () {
    return {
      isLittleScroll: false,

      payloadSurveyId: '',
      payloadClients: '',
      payloadStores: '',
      payloadDates: null,
      payloadStatus: null
    }
  },
  computed: {},
  methods: {
    handleFilter () {
      const payload = {}
      if (this.payloadSurveyId) payload.surveyId = this.payloadSurveyId
      if (this.payloadClients) payload.clients = this.payloadClients
      if (this.payloadStores) payload.stores = this.payloadStores
      if (this.payloadDates) payload.date = this.payloadDates
      if (this.payloadStatus) payload.status = this.payloadStatus

      this.loadAPI(payload)
    },
    updateSelection (props) {
      switch (props.type) {
        case 'clients':
          this.payloadClients = props.data
          break
        case 'stores':
          this.payloadStores = props.data
          break
        case 'dates':
          this.payloadDates = props.data
          break
        case 'status':
          this.payloadStatus = props.data
          break

        default:
          break
      }
    }
  },
  mounted () {
    this.isLittleScroll = this.isLittleScrollProps
  },
  watch: {
    isLittleScrollProps (val) {
      this.isLittleScroll = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.adjust-padding-input {
  padding: 2.4px;
  width: 230px;
}
</style>
