<template>
  <div class="averta-bold is-flex">
    <div :style="{ width: `${widthColumnNavProps}px`, height: `${heightColumnNavProps}px` }" class="nav-col nav-col-date border-right-5 border-left-1 sticky-all z-index-5 bg-grey-8">
      <p class="averta-bold">Tanggal</p>
    </div>
    <div class="nav-table">
      <div v-for="(item, index) in dataNavbar" :key="`nav-${index}`" :style="{ width: `${widthColumnProps}px`, height: `${heightColumnNavProps}px` }" :class="{ 'border-radius-top-right-10 border-right-2': index + 1 === dataNavbar.length, 'border-right-5': index === 1 }" class="nav-col bg-grey-8">
        <div v-if="item.tooltipDesc">
          <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true">
            <template v-slot:content>
              <p class="averta-bold has-text-left">{{ item.title }}</p>
              <p class="averta-regular has-text-left tooltip-desc" :class="{'w-additional-desc': item.additionalDesc}">{{ item.tooltipDesc }}</p>

              <div v-if="item.additionalDesc" class="additional-desc p-1 my-1">
                <p class="color-black label-11 averta-regular">{{item.additionalDesc}}</p>
              </div>
            </template>
            <div class="is-flex is-align-items-center">
              <p class="mr-2 title-navbar">{{ item.title }}</p>
              <InfoIcon color="#3185FC" width="18" height="18" />
            </div>
          </b-tooltip>
        </div>
        <div v-else>
          <p class="averta-bold">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoIcon } from '@/components/icons'

export default {
  name: 'NavbarTableProgress',
  components: {
    InfoIcon
  },
  props: {
    widthColumnProps: {
      type: Number
    },
    widthColumnNavProps: {
      type: Number
    },
    heightColumnNavProps: {
      type: Number
    }
  },
  data () {
    return {
      dataNavbar: [
        {
          title: 'Survey Diminta',
          afterTitle: 'survey-diminta',
          tooltipDesc: 'Daftar permintaan survey yang dibuat oleh client'
        },
        {
          title: 'Survey Terjadwal',
          afterTitle: 'survey-terjadwal',
          tooltipDesc: 'Daftar survey yang harus dilakukan'
        },
        {
          title: 'Survey Dilakukan',
          afterTitle: 'survey-dilakukan',
          tooltipDesc: 'Daftar Survey tampil apabila konsultan melakukan absen'
        },
        {
          title: 'Proses SM',
          afterTitle: 'proses-sm'
        },
        {
          title: 'Proses Purchasing',
          afterTitle: 'proses-purchasing'
        },
        {
          title: 'Proses BS',
          afterTitle: 'proses-bs'
        },
        {
          title: 'Penawaran Terkirim',
          afterTitle: 'penawaran-terkirim'
        },
        {
          title: 'Penawaran Disetujui',
          afterTitle: 'penawaran-disetujui'
        },
        {
          title: 'Penawaran Ditolak',
          afterTitle: 'penawaran-ditolak'
        },
        {
          title: 'SPK SIK Diterima',
          afterTitle: 'spk-sik-diterima'
        },
        {
          title: 'Persiapan Material Purchasing',
          afterTitle: 'persiapan-material-purchasing',
          tooltipDesc: 'Daftar proyek yang sedang menunggu preparasi material'
        },
        {
          title: 'Proyek Dibuat',
          afterTitle: 'proyek-dibuat'
        },
        {
          title: 'Assign Tukang',
          afterTitle: 'assign-tukang'
        },
        {
          title: 'Pekerjaan Dilakukan',
          afterTitle: 'pekerjaan-dilakukan',
          tooltipDesc: 'Daftar proyek yang sedang dalam proses pengerjaan tukang',
          additionalDesc: 'Perubahan status absen secara manual akan mempengaruhi riwayat progress untuk proyek ini pada halaman Report Progress'
        },
        {
          title: 'Pekerjaan Selesai',
          afterTitle: 'pekerjaan-selesai',
          tooltipDesc: 'Daftar proyek menunggu konfirmasi tukang jagoan'
        },
        {
          title: 'Konfirmasi Pekerjaan Selesai',
          afterTitle: 'konfirmasi-pekerjaan-selesai',
          tooltipDesc: 'Proyek sedang menunggu Konfirmasi client atau menunggu invoice diterbitkan'
        },
        {
          title: 'Invoice Terkirim',
          afterTitle: 'invoice-terkirim',
          tooltipDesc: 'Daftar invoice yang sudah diterbitkan oleh Business Support'
        },
        {
          title: 'Terbayar',
          afterTitle: 'terbayar'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.nav-table {
  display: flex;
  position: relative;
}

.nav-col {
  border-right: 1px solid $color-grey-2;
  border-top: 1px solid $color-grey-2;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.nav-col-date {
    border-top-left-radius: 10px;
  }
}

.tooltip-desc {
  display: inline-block;
  white-space: normal;
  width: 15em;
}

.title-navbar {
  max-width: 13em;
}

.w-additional-desc {
  width: auto !important;
}

.additional-desc {
  background: $color-white;
  border-radius: 8px;
  width: 250px;
  white-space: normal;
  text-align: left;
}
</style>
