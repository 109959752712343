export const defaultStateClient = () => ({
  payloadClient: {
    typeSidebar: 'add', // add or edit
    showSidebar: false,
    closeSidebar: '', // unused ?
    isEmptyForm: true,
    dataForm: {
      company_name: '',
      name: '',
      logo: '',
      imgFile: null
    },
    site_managers: null,
    survey_group_session_ids: null
  }
})

export const defaultStateStore = () => ({
  payloadStore: {
    typeSidebar: 'add-store', // 'add-store' or 'edit-store'
    formStore: {
      store_name: '',
      store_address: '',
      store_phone: '',
      latitude: '',
      longitude: '',
      address: '',
      detail_address: ''
    },
    formPIC: [
      {
        pic_name: '',
        pic_email: '',
        pic_phone: ''
      }
    ],
    selectedStore: {}
  }
})
