<template>
  <div class="wrap-collapse mb-5">
    <b-collapse class="panel shadow-none" animation="slide" v-model="isOpenCollapse">
      <template #trigger>
        <div class="p-4 is-flex is-align-items-center is-justify-content-space-between">
          <div>
            <p class="averta-bold label-16">Ringkasan</p>
            <p v-if="!isLoading && data" class="color-gray">{{ formatDate(data.from_date, 'DD/MM/YYYY') }} - {{ formatDate(data.to_date, 'DD/MM/YYYY') }}</p>
          </div>
          <div class="is-flex is-align-items-center">
            <ArrowFoward color="#252525" class="mr-2" :style="isOpenCollapse ? 'transform: rotate(-90deg)' : 'transform: rotate(90deg)'" />
          </div>
        </div>
      </template>

      <div v-if="!isLoading" class="panel-block is-flex is-align-items-center is-justify-content-start px-4 pt-2 pb-4">
        <div class="is-flex is-flex-wrap-wrap c-w-100">
          <div v-for="(summary, index) in dataTitleSummary" :key="index" class="p-2 c-h-100 c-w-20">
            <div class="card-skill">
              <p class="averta-bold">{{ summary.title }}</p>
              <p class="color-gray">Total : {{ formatTotal(summary.status_name) }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { formatDateHelper } from '@/helpers'
import { ArrowFoward } from '@/components/icons'

export default {
  name: 'SummaryProgress',
  components: {
    ArrowFoward
  },
  props: {
    data: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  data () {
    return {
      isOpenCollapse: true,
      dataTitleSummary: [
        { status_name: 'survey-diminta', title: 'Survey Diminta' },
        { status_name: 'survey-terjadwal', title: 'Survey Terjadwal' },
        { status_name: 'survey-dilakukan', title: 'Survey Dilakukan' },
        { status_name: 'proses-sm', title: 'Proses SM' },
        { status_name: 'proses-purchasing', title: 'Proses Purchasing' },
        { status_name: 'proses-bs', title: 'Proses BS' },
        { status_name: 'penawaran-terkirim', title: 'Penawaran Terkirim' },
        { status_name: 'penawaran-disetujui', title: 'Penawaran Disetujui' },
        { status_name: 'penawaran-ditolak', title: 'Penawaran Ditolak' },
        { status_name: 'spk-sik-diterima', title: 'SPK SIK Diterima' },
        { status_name: 'persiapan-material-purchasing', title: 'Persiapan Material Purchasing' },
        { status_name: 'proyek-dibuat', title: 'Proyek Dibuat' },
        { status_name: 'assign-tukang', title: 'Assign Tukang' },
        { status_name: 'pekerjaan-dilakukan', title: 'Pekerjaan Dilakukan' },
        { status_name: 'pekerjaan-selesai', title: 'Pekerjaan Selesai' },
        { status_name: 'konfirmasi-pekerjaan-selesai', title: 'Konfirmasi Pekerjaan Selesai' },
        { status_name: 'invoice-terkirim', title: 'Invoice Terkirim' },
        { status_name: 'terbayar', title: 'Terbayar' }
      ]
    }
  },
  methods: {
    formatDate (date, format) {
      if (!date) return ''
      return formatDateHelper({ date, format })
    },
    formatTotal (value) {
      if (this.data) {
        const find = this.data.list.find(obj => obj.status_name === value)
        if (find) {
          return find.total
        }
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-collapse {
  background: #FAFAFA;
  border-radius: 20px;
  border: 1px solid #F0F0F0;
  overflow: hidden;
}

.card-skill {
  background: white;
  padding: 13px 15px;
  border-radius: 12px;
  border: 1px solid $color-grey-8;
  height: 100%;
}

.columns {
  margin: 0px !important;
}

@media only screen and (max-width: 600px) {
  .card-skill {
    width: 100%;
    padding: 12px 16px;
    margin-right: 0px;
    margin-bottom: 12px;
  }

  .card-skill:is(:last-child) {
    margin-bottom: 18px !important;
  }
}
</style>
